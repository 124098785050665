<template>
  <div class="w-full flex gap-10">
    <Wrapper class="max-w-2xl">
      <h2 class="text-left text-3xl text-gray-600 py-2">
        <b>Formulario para presentar una queja</b>
      </h2>
      <p class="text-left text-sm text-gray-400 pb-8">
        Complete los campos solicitados y la información detallada.
      </p>
      <div>
        <ComplaintForm />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import ComplaintForm from '@/components/Dashboard/Complaints/Complaint/ComplaintForm'

export default {
  components: {
    ComplaintForm,
  },
  data() {
    return {
      loading: false,
      disabled: false,
    }
  },
}
</script>

<style></style>
