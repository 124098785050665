<template>
  <form @submit.prevent="sendComplaint" class="w-full px-0 py-0">
    <h2 class="text-xl text-left text-gray-500">
      <b>Datos de contacto</b>
    </h2>
    <div class="w-full">
      <BaseInput
        ref="name"
        type="text"
        id="name"
        label="Nombre completo"
        placeholder="Ej: Lina Maria De Castro Jaramillo"
        v-model="form.nombres"
        :required="true"
      />
      <BaseInput
        ref="email"
        type="text"
        id="email"
        label="Correo electrónico"
        placeholder="Ej: peter@ejemplo.com"
        v-model="form.email"
      />
      <BaseInput
        ref="address"
        type="text"
        id="address"
        label="Dirección"
        placeholder="Ej: Calle 12 # 34 - 56"
        v-model="form.direccion"
      />
      <BaseRadio
        ref="personType"
        name="personType"
        id="personType"
        label="Tipo de persona"
        :radioOptions="formOptions.optionsPersonType"
        v-model="form.tipo_persona"
        :required="true"
      />
      <BaseSelect
        ref="documentType"
        id="documentType"
        label="Tipo de documento"
        :selectOptions="formOptions.optionsIdentificationType"
        v-model="form.tipo_id_CF"
        :required="true"
      />
      <BaseInput
        ref="document"
        type="text"
        id="document"
        label="Número de documento"
        placeholder="Ej: 123456"
        v-model="form.numero_id_CF"
        :required="true"
      />
    </div>
    <div class="w-full mb-8">
      <BaseSelect
        ref="sexo"
        id="sexo"
        label="Sexo"
        :selectOptions="formOptions.optionsGender"
        v-model="form.sexo"
      />
      <BaseRadio
        ref="lgtbiq"
        name="lgtbiq"
        label="LGTBIQ+"
        :radioOptions="formOptions.optionsYN"
        v-model="form.lgbtiq"
      />
      <BaseRadio
        ref="especialCondicion"
        name="especialCondicion"
        label="¿Tiene alguna condición especial?"
        :radioOptions="formOptions.optionsYN"
        v-model="specialCondition"
      />
      <BaseSelect
        ref="typeSpecialCondition"
        v-if="specialCondition == 1"
        id="typeSpecialCondition"
        label="¿Cuál?"
        :selectOptions="formOptions.optionsConditions"
        v-model="form.condicion_especial"
      />
    </div>
    <div class="w-full mb-5">
      <h2 class="my-5 text-xl text-left text-gray-500">
        <b>Detalle de la queja</b>
      </h2>
      <BaseInput
        v-if="me.company.generated_code && me.company.generated_code === 2"
        type="text"
        id="complaint_code"
        label="Código de la queja"
        placeholder="Ej: 1111111111"
        v-model="form.codigo_queja"
      />
      <div>
        <BaseSelect
          ref="country"
          id="country"
          label="Seleccione el país donde ocurrio la incidencia"
          :selectOptions="formOptions.optionsCountries"
          v-model="form.codigo_pais"
          :required="true"
        />
      </div>
      <div v-if="form.codigo_pais === '170'">
        <BaseSelect
          ref="department"
          id="department"
          label="Seleccionar Departamento"
          :selectOptions="formOptions.optionsDepartments"
          v-model="form.departamento_cod"
          :required="true"
        />
        <BaseSelect
          ref="city"
          id="city"
          label="Seleccionar Municipio"
          :selectOptions="optionsMunicipalities"
          v-model="form.municipio_cod"
          :required="true"
        />
      </div>
      <BaseSelect
        ref="reason"
        id="reason"
        label="¿Cuál es el motivo de su inconformidad?"
        :selectOptions="formOptions.optionsReasons"
        v-model="form.macro_motivo_cod"
        :required="true"
      />
      <BaseSelect
        ref="product"
        id="product"
        label="¿Su queja está relacionada con alguno de los siguientes productos?"
        :selectOptions="formOptions.optionsProducts"
        v-model="form.producto_cod"
        :required="true"
      />
      <BaseTextarea
        ref="productDescription"
        id="productdescription"
        label="Si lo desea amplíe el detalle de este producto"
        placeholder="Descripción"
        v-model="form.producto_nombre"
        :required="true"
        maxlength="100"
      />
      <BaseSelect
        ref="channel"
        id="channel"
        label="¿A través de que canal se originó su inconformidad?"
        :selectOptions="formOptions.optionsChannel"
        v-model="form.canal_cod"
        :required="true"
      />
      <BaseTextarea
        ref="describe"
        id="describe"
        label="Describa los hechos brevemente:"
        placeholder="Mensaje"
        v-model="form.texto_queja"
        :required="true"
        maxlength="4500"
      />
      <DragDrop
        label="En caso de que quiera aportar soportes de los hechos mencionados, adjúntelos aquí:"
        buttonLabel="Seleccione los archivos"
        id="complaintFiles"
        :fileList="complaintFiles"
        @files-handler="setComplaintFiles"
      />
    </div>
    <p class="my-2 text-xs text-center text-gray-500">
      *Peso máximo de soportes y anexo de autorización de poder 30MB
    </p>
    <div v-if="feedback">
      <p class="mt-8 text-sm text-center text-red-500">{{ feedback }}</p>
    </div>
    <div class="mt-8 mx-auto w-full">
      <Button
        type="submit"
        id="sendComplaint"
        class="w-full"
        :disable="loadingComplaint"
        :loading="loadingComplaint"
      >
        Registrar queja
      </Button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      form: {
        codigo_queja: '',
        codigo_pais: '170',
        departamento_cod: '',
        municipio_cod: '',
        canal_cod: '',
        producto_cod: '',
        producto_nombre: '',
        macro_motivo_cod: '',
        nombres: '',
        email: '',
        tipo_id_CF: '',
        numero_id_CF: '',
        tipo_persona: '',
        sexo: '',
        lgbtiq: '',
        condicion_especial: '',
        texto_queja: '',
        direccion: '',
      },
      feedback: '',
      specialCondition: '',
      optionsMunicipalities: [],
      complaintFiles: [],
      filesSize: 0,
      loadingComplaint: false,
    }
  },
  computed: {
    ...mapState({
      formOptions: 'form',
    }),
    ...mapState('session', ['me']),
  },
  methods: {
    async sendComplaint() {
      if (this.invalidForm()) return
      this.loadingComplaint = true
      const response = await this.$api.complaints.createComplaint(
        this.form,
        this.complaintFiles
      )
      this.loadingComplaint = false
      if (!response || response.status !== 201) {
        this.$showNotification('error', response.data)
        return
      }
      this.$showNotification('success', 'Se creó correctamente la queja')
      this.$router.push('/dashboard/complaints/')
    },
    setComplaintFiles(files) {
      let size = this.checkFileSize([...files])
      if (size <= 30) {
        this.complaintFiles = files
        this.feedback = ''
      } else {
        this.feedback =
          'Con el archivo que intenta cargar, se supera el peso máximo permitido de los anexos. Valide la información.'
      }
    },
    checkFileSize(files) {
      let size = 0
      files.forEach((item) => {
        size += item.size
      })
      size = size / (1024 * 1024)
      return size
    },
    invalidForm() {
      this.feedback = ''
      let refs = { ...this.$refs }
      for (let ref in refs) {
        if (refs[ref] && refs[ref].required && refs[ref].value === '') {
          refs[ref].$el.scrollIntoView({ behavior: 'smooth' })
          refs[ref].setInvalid(true)
          return true
        }
      }
      if (this.form.numero_id_CF.length > 15) {
        this.feedback =
          'Número de documento excedió los caracteres permitidos (máximo 15 caracteres)'
        return true
      }
      return false
    },
  },
  watch: {
    'form.departamento_cod': async function () {
      if (this.form.departamento_cod == '') return
      let options = await this.$store.dispatch(
        'form/loadMunicipalitiesOptions',
        { department: this.form.departamento_cod }
      )
      this.optionsMunicipalities = options
    },
    // TODO
    'form.entidad_cod': async function (value) {
      if (value == '') return
      // Reiniciar valores
      this.form.macro_motivo_cod = ''
      this.form.producto_cod = ''

      let company = this.formOptions.optionsCompanies.find((company) => {
        return company.id == value
      })
      this.form.tipo_entidad = company.company_type
      let optionsReasons = await this.$store.dispatch(
        'form/loadReasonsOptions',
        { company: this.form.entidad_cod }
      )
      let optionsProducts = await this.$store.dispatch(
        'form/loadProductsOptions',
        { company: this.form.entidad_cod }
      )
      this.optionsReasons = optionsReasons
      this.optionsProducts = optionsProducts
    },
    specialCondition(value) {
      if (value !== 2) {
        this.form.condicion_especial = ''
        return
      }
      this.form.condicion_especial = 98
    },
  },
}
</script>

<style></style>
